import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import arrowRight from '../../content/assets/arrow-right.svg'
import contacts from "../../content/assets/contacts.svg"
import locationmap from "../../content/assets/map-africa-sugar.svg"
import locationmapsa from "../../content/assets/map-sugar-areas-new.svg"


export default () => {


    const data = useStaticQuery(graphql`
    query ImgQuery {
      allFile(filter: { extension: { eq: "jpg" } }) {
        edges {
          node {
           name 
           childImageSharp{
                  fluid( maxWidth: 1280, quality: 80 ) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
          }
        }
      }
    }
  `)

  const img = data.allFile.edges



  
    return (
      <Layout>
        
        <SEO
          title="The official site of the South African Sugar Industry"
          keywords={[`south african sugar industry`, `sa sugar`, `sasa` ]}
        />

            <div className="w-100 cf">
            <div className="ph3 ph4-ns pv1">
            <div className="mw9 center">

              
             
                 <Link to='/category/#sa sugar industry'>
              
                    <div className="w-100 relative">
                      {img.filter(x => x.node.name === "cover").map(({ node }) => {
                      return ( <Img className="vh-40 vh-50-ns w-100" key={node.name}  fluid={node.childImageSharp.fluid} />)
                      })}
                      <div className="absolute z-4 bottom-0 left-0 right-0 z-3 pv0 pv4-ns w-100 ">
                        <div className="tr ttu ph4 pb4">
                            <div className="f3 f2-ns white fw3 mb1 lh-solid tracked">South African </div>
                            <div className="f3 f2-ns white fw3 mb1 lh-solid tracked">Sugar Industry</div>
                            <div className="f3 f1-ns white fw5 mb1 lh-solid mb0 tracked">DIRECTORY 2019</div>
                            <div className="f5 white fw5 mt2 lh-solid tracked-mega dib">OVERVIEW</div>
                            <img className="square-1 mt3 invert nb1 ml3" src={arrowRight} alt="arrow-right"/>
                        </div>
                      </div>
                    </div>
          
                </Link>
          

                <div className="w-100 cf mv3">
                 <div className="flex flex-wrap flex-wrap-m justify-between-l">

                   <div className="w-100 w-50-m w-third-l pr3-ns">
                    <Link to='/category/#cane growing in south africa'>
                        <div className="h-fluid cover">
                            {img.filter(x => x.node.name === "growers-index").map(({ node }) => {
                            return ( <Img  key={node.name} fluid={node.childImageSharp.fluid} />)
                            })}
                        </div>
                        <div className="pv4 db ">
                              <div className="f8 theme-menu  fw5 mb0 lh-solid tracked-mega mb2">GROWING</div>
                              <div className="f4 f5-ns black fw5 mt1 mb3 lh-1-6 tracked-sm">Cane Growing in South Africa</div>
                              <div className="f5 fw3 dark-gray tracked-sm lh-copy">Sugarcane is a strategic crop for KwaZulu-Natal and Mpumalanga, where sugarcane production is located, comprising a substantial percentage of field crop gross farming income across the two provinces.</div>
                              <img className="square-1 mt3" src={arrowRight} alt="arrow-right"/>
                        </div>
                      </Link>
                      </div>
                    
                      <div className="w-100 w-50-m w-third-l pr0-m pr3-l">
                        <Link to='/category/#milling and refining'>
                              <div className="h-fluid cover">
                                {img.filter(x => x.node.name === "millers-index").map(({ node }) => {
                                return ( <Img  key={node.name}  fluid={node.childImageSharp.fluid} />)
                                })}
                              </div>
                            <div className="pv4 db">
                                  <div className="f8 theme-menu  fw5 mb0 lh-solid tracked-mega mb2">MILLING</div>
                                  <div className="f4 f5-ns black fw5 mt1 mb3 lh-1-6 tracked-sm">Sugar Milling and Refining in South Africa</div>
                                  <div className="f5 fw3 dark-gray tracked-sm lh-copy">The milling sector employs upwards of 7000 people at 14 sugar mills and at the companies’ administration offices. </div>
                                  <img className="square-1 mt3" src={arrowRight} alt="arrow-right"/>
                          </div>  
                        </Link>
                      </div>
                  
                      <div className="w-100 w-50-m w-third-l"> 
                        <Link to='/sasa/overview/'>
                          <div className="h-fluid cover">
                          {img.filter(x => x.node.name === "sasa-index").map(({ node }) => {
                          return ( <Img  key={node.name}  fluid={node.childImageSharp.fluid} />)
                          })}
                          </div>
                          <div className="pv4 db">
                                <div className="f8 theme-menu  fw5 mb0 lh-solid tracked-mega mb2 ttu">SASA</div>
                                <div className="f4 f5-ns black fw5 mt1 mb3 lh-1-6 tracked-sm">South African Sugar Association</div>
                                <div className="f5 fw3 dark-gray tracked-sm lh-copy">The South African Sugar Association (SASA) provides a range of specialist services that enhance the profitability, global competitiveness and sustainability of the South African sugar industry.</div>
                                <img className="square-1 mt3" src={arrowRight} alt="arrow-right"/>
                        </div> 
                        </Link>
                      </div>
                   

                  </div>
                </div>

                <div className="w-100 cf mt5 mb6 dim">
                        <Link className="w-100 cf" to='/factsandfigures'>
                          <div className="fl w-100 w-50-m w-40-l  pr4 db">
                                  <div className="f8 theme-menu  fw5 mb0 lh-solid tracked-mega mb2 ttu">FACTS AND FIGURES</div>
                                  <div className="f3 fw1 black mt1 mb3 pb2 tracked-sm">Facts and Figures of the South African Sugar Industry</div>
                                  <div className="f5 fw3 dark-gray tracked-sm lh-copy ">All the facts and figures about sugar cane growing, milling and refining, crop data yields and rainfall.  </div>
                                  <img className="square-1 mt3" src={arrowRight} alt="arrow-right"/>
                          </div> 
                          <div className="fl w-100 w-50-m w-60-l">
                            <div className="fl w-third pr4">
                              {img.filter(x => x.node.name === "factsandfigures").map(({ node }) => {
                               return ( <Img className="mw6 h-fluid"  key={node.name}  fluid={node.childImageSharp.fluid} />)
                              })}
                            </div>
                            <div className="fl w-third pr4 ">
                              {img.filter(x => x.node.name === "factsandfigures-1").map(({ node }) => {
                              return ( <Img className="mw6 h-fluid"  key={node.name}  fluid={node.childImageSharp.fluid} />)
                              })}
                            </div>
                            <div className="fl w-third pr4">
                              {img.filter(x => x.node.name === "factsandfigures-2").map(({ node }) => {
                              return ( <Img className="mw6 h-fluid"  key={node.name}  fluid={node.childImageSharp.fluid} />)
                              })}
                            </div>
                          </div>
                      </Link>
                </div>



                <div className="w-100 cf mt5 mb6 ">
                        <Link className="w-100 cf" to='/directory'>
                          <div className="flex flex-wrap items-start">
                            <div className="fl w-100 w-50-m w-60-l nt4">
                              <img className="w-100 self-start"  src={contacts} alt="contacts"/>
                            </div>
                            <div className="fl w-100 w-50-m w-40-l  pr4 ">
                                    <div className="f8 theme-menu  fw5 mb0 lh-solid tracked-mega mb2 ttu">CONTACTS DIRECTORY</div>
                                    <div className="f3 fw1 black mt1 mb3 pb2 tracked-sm">Directory of the South African Sugar Industry</div>
                                    <div className="f5 fw3 dark-gray tracked-sm lh-copy ">All the people and organisational contacts you need.  </div>
                                    <img className="square-1 mt3" src={arrowRight} alt="arrow-right"/>
                            </div> 
                          </div>
                      </Link>
                </div>
                <div id="locations" className="mb4 h1"></div>
        </div>
        </div>
        </div>

        <div className="w-100 mw9 center pl4 pl4-m pb5">
          <div className="f8 theme-menu  fw5 mb0 lh-solid tracked-mega mb2 ttu">Location</div>
          <div className="f3 fw1 black mt1 mb3 pb2 tracked-sm">Operating Locations</div>
        </div>

        <div  className="w-100 bg-theme-75">
                <div className="mw8 center pb4 flex flex-wrap items-start">
                  <img className="mw4 mw6-ns nt5 pl3 pl4-ns" src={locationmap} alt="locationmap"/>
                  <img className="w-100 w-70-ns nt5-ns" src={locationmapsa} alt="locationmapsa"/>
                </div>
       </div>

 


      </Layout>
    )
  }




